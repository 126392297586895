export default class ConfigManager {

    constructor() {

        this.id = 0;
        this.taxboleto = 0;
        this.taxCreditCard = 0;
        this.taxRecurrentCreditCard = 0;
    }

}