<template>
  <div class="taxas">
    <div class="grid-box">
      <Breadcrumb :levels="breadcrumbLevels" />
      <div class="main-block footer">
        <v-layout>
          <div class="boxapp">
            <div class="boxapp-head">
              <a href="#" @click="$router.go(-1)">
                <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
              </a>
              <h1>Edição de Taxas</h1>
            </div>

            <div class="box-main">
              <div class="mb-5" v-for="paymentMethodProvider in paymentMethodProviders" :key="paymentMethodProvider.id">
                <h3>{{ paymentMethodProvider.name }}</h3>
                <div class="section">
                  <div class="" v-if="paymentMethodProvider.name == 'PagarMe'">
                    <div class="mt-4" v-if="data.asaas.d2.length > 0">
                      <strong>Cartão de crédito parcelamento recebedor D+2</strong>

                      <div class="d-flex flex-wrap flex-gap">
                        <div style="width: 6.5rem;" v-for="(item, index) in data.pagarMe.d2" :key="item.key">
                          <InputMoney :autoFocusOnValidationFail="false" v-model="data.pagarMe.d2[index].value" :textLabel="`${item.key}x ${item.key === 1 ? '(à vista)' : ''}`" :precision="2" prefix="% " />
                        </div>
                      </div>
                    </div>
                    <div class="mt-4" v-if="data.pagarMe.d30.length > 0">
                      <strong>Cartão de crédito parcelamento recebedor D+30</strong>

                      <div class="d-flex flex-wrap flex-gap">
                        <div style="width: 6.5rem;" v-for="(item, index) in data.pagarMe.d30" :key="item.key">
                          <InputMoney :autoFocusOnValidationFail="false" v-model="data.pagarMe.d30[index].value" :textLabel="`${item.key}x ${item.key === 1 ? '(à vista)' : ''}`" :precision="2" prefix="% " />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="" v-if="paymentMethodProvider.name == 'Asaas'">
                    <div class="mt-4" v-if="data.asaas.d2.length > 0">
                      <strong>Cartão de crédito parcelamento recebedor D+2</strong>

                      <div class="d-flex flex-wrap flex-gap">
                        <div style="width: 6.5rem;" v-for="(item, index) in data.asaas.d2" :key="item.key">
                          <InputMoney :autoFocusOnValidationFail="false" v-model="data.asaas.d2[index].value" :textLabel="`${item.key}x ${item.key === 1 ? '(à vista)' : ''}`" :precision="2" prefix="% " />
                        </div>
                      </div>
                    </div>
                    <div class="mt-4" v-if="data.asaas.d30.length > 0">
                      <strong>Cartão de crédito parcelamento recebedor D+30</strong>

                      <div class="d-flex flex-wrap flex-gap">
                        <div style="width: 6.5rem;" v-for="(item, index) in data.asaas.d30" :key="item.key">
                          <InputMoney :autoFocusOnValidationFail="false" v-model="data.asaas.d30[index].value" :textLabel="`${item.key}x ${item.key === 1 ? '(à vista)' : ''}`" :precision="2" prefix="% " />
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-layout row mw-half>
                    <v-flex>
                      <InputMoney v-if="has('taxBoleto', paymentMethodProvider)"
                        v-model="paymentMethodProvider.configManager.taxBoleto" textLabel="Taxa de transação via boleto"
                        :precision="2" />
                    </v-flex>
                  </v-layout>
                  <v-layout row mw-half>
                    <v-flex>
                      <InputMoney v-if="has('taxAccountDebit', paymentMethodProvider)" v-model="paymentMethodProvider.configManager.taxAccountDebit
                        " textLabel="Taxa de Débito em conta para transação única" :precision="2" prefix="% " />
                    </v-flex>
                  </v-layout>
                  <v-layout row mw-half>
                    <v-flex>
                      <InputMoney v-if="
                        has('taxRecurrentAccountDebit', paymentMethodProvider)
                      " v-model="paymentMethodProvider.configManager
                        .taxRecurrentAccountDebit
                        " textLabel="Taxa de Débito em conta para transação recorrente" :precision="2" prefix="% " />
                    </v-flex>
                  </v-layout>
                  <v-layout row mw-half>
                    <v-flex>
                      <InputMoney v-if="has('taxPix', paymentMethodProvider)"
                        v-model="paymentMethodProvider.configManager.taxPix" textLabel="Taxa do Pix" :precision="2"
                        prefix="% " />
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </div>
          </div>

          <div class="box-help-spacer"></div>
          <HelpBox :text="helpTextBox" />
        </v-layout>

        <div class="footer-step">
          <v-btn flat round color="white" class="btn-primary flex-inherit px-5 ml-auto" large
            @click="save">Salvar</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="plain/text">
import HeroInner from "@/components/HeroInner.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import HelpBox from "@/components/HelpBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfigManagerService from "@/scripts/services/configManager.service.js";
import ReceiverParcelament from "@/scripts/services/receiverParcelament.service.js";
import ConfigManager from "@/scripts/models/configManager.model.js";
import CommonHelper from "@/scripts/helpers/common.helper";
import PaymentMethodProvider from "../../../../scripts/models/enums/paymentMethodProvider.enum";

export default {
  components: {
    HeroInner,
    HelpBox,
    HelpBoxText,
    Breadcrumb,
  },
  data: () => ({
    configManagerService: new ConfigManagerService(),
    receiverParcelamentService: new ReceiverParcelament(),
    paymentMethodProviders: [],
    breadcrumbLevels: [{ text: "Início" }, { text: "Configurações" }],
    helpTextBox: HelpBoxText.Taxes,
    data: {
      asaas: {
        d2: [],
        d30: [],
      },
      pagarMe: {
        d2: [],
        d30: [],
      }
    },
  }),
created() {
  this.load();
},
methods: {
  load() {
    this.receiverParcelamentService.listReceiverParcelaments().then(data => {
      this.data = data
    })
    this.configManagerService
      .listAllPaymentMethodProvider()
      .then(this.paymentMethodProviderCallback);
  },
  async save() {
    await this.configManagerService.savePaymentMethodProvider(this.paymentMethodProviders);
    await this.receiverParcelamentService.save(this.data);
    this.callbackSave();
  },
  has(method, obj) {
    switch (method) {
      case "taxPix":
        return obj.paymentMethodPaymentMethodProviders.some(
          (el) => el.paymentMethodId == PaymentMethod.Pix
        );
        break;
      case "taxBoleto":
        return obj.paymentMethodPaymentMethodProviders.some(
          (el) => el.paymentMethodId == PaymentMethod.Ticket
        );
        break;
      case "taxRecurrentCreditCard":
      case "taxCreditCard":
        return obj.paymentMethodPaymentMethodProviders.some(
          (el) => el.paymentMethodId == PaymentMethod.CreditCard
        );
        break;
      case "taxRecurrentAccountDebit":
      case "taxAccountDebit":
        return obj.paymentMethodPaymentMethodProviders.some(
          (el) => el.paymentMethodId == PaymentMethod.AccountDebit
        );
        break;
      default:
        break;
    }
  },
  paymentMethodProviderCallback(data) {
    this.paymentMethodProviders = data;
    this.paymentMethodProviders.forEach((el) => {
      if (el.configManager == null) el.configManager = new ConfigManager();
    });
  },
  callbackSave(data) {
    CommonHelper.swal("", "Taxas atualizadas com sucesso!", "success");
    this.load();
  },
},
};
</script>